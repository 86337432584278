.about-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.about-container h1{
    color: white;
    margin: 50px 0;
}

.about-container p{
    color: white;
    width: 50%;
    margin: 0 25% 0 25%;
    font-size: medium;
}
@media screen and (max-width: 768px) {
    .about-container p {
        width: 80%;
        margin: 0 10% 0 10%;
        font-size: medium;
    }
}