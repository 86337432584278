.dropdown-menu {
    width: 130px;
    position: absolute;
    top: 70px;
    list-style: none;
    text-align: start;
}

.dropdown-menu li {
    background: #10171a;
    cursor: pointer;
}

.dropdown-menu li:hover {
    background: #151e20;
}

.dropdown-menu.clicked {
    display: none;
}

.dropdown-link {
    display: block;
    height: 100%;
    width: 100%;
    text-decoration: none;
    color: #fff;
    padding: 16px;
}

@media screen and (max-width: 960px) {
    .fa-caret-down {
        display: none;
    }
}