video {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -1;
}

.custom-bg{
    background: url("../../../public/images/img-home.jpeg") center center/cover no-repeat;
    margin-top: 70vh;
    height: 2300px;
    width: 100vw;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.4);
}


.hero-container {
    height: 100vh;
    min-height: 400px;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
}

.hero-container > h1 {
    color: #fff;
    font-size: 6vw;
    margin-top: 30vh;
    text-align: center;
}

.hero-container > p {
    margin-top: 8px;
    color: #fff;
    font-size: 2vw;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
    text-align: center;
}

.hero-btns {
    margin-top: 32px;
}

.hero-btns .btn {
    margin: 6px;
}

.ref-div {
    position: absolute;
    top: 100vh;
}

@media screen and (max-width: 768px) {
    .hero-container > h1 {
        font-size: 40px;
        margin-top: 30vh;
    }

    .hero-container > p {
        font-size: 15px;
    }

    .btn-mobile {
        display: block;
        text-decoration: none;
    }

    .btn {
        width: 100%;
    }
}