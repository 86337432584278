.search{
    width: 100%;
    margin: 17px auto;
    height: 35px;
    background: white;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.search input{
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    background: transparent;
    padding: 0 10px;
    font-size: 0.95rem;
}

.search button{
    border:none;
    outline: none;
    width: 50px;
    background: transparent;
}

.search button:hover{
    color: blue;
}