* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'PT Sans', sans-serif;
}

html{
    background-color: rgb(16, 16, 20);
}

.home,
.services,
.products,
.sign-up {
    display: flex;
    height: 90vh;
    align-items: center;
    justify-content: center;
    font-size: 3rem;
}

.services {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 100px;
}

.products {
    background-position: center;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 100px;
}

.sign-up {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 100px;
}