.navbar {
    background: rgb(16, 16, 20);
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: sticky;
    top: 0;
    z-index: 999;
}

.navbar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    max-width: 1500px;
}

.navbar-logo {
    color: #fff;
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
}

.fa-ghost {
    margin-left: 0.8rem;
    font-size: 1.8rem;
}

.nav-menu {
    display: grid;
    grid-template-columns: repeat(4, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width: 60vw;
    justify-content: end;
    margin-right: 2rem;
}

.search-container{
    display: flex;
    width: 200px;
    align-items: center;
}

.nav-item {
    height: 70px;
}

.nav-links {
    color: #fff;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
}

.nav-links:hover {
    border-bottom: 4px solid #fff;
    transition: all 0.2s ease-out;
}

.fa-bars {
    color: #fff;
}

.media-display {
    display: none;
}

.nav-links-mobile {
    display: none;
}

.menu-icon {
    display: none;
}

@media screen and (max-width: 960px) {

    .NavbarItems {
        position: relative;
    }

    .nav-menu {
        display: inline-block;
        flex-direction: column;
        width: 100%;
        height: 80vh;
        position: absolute;
        top: 70px;
        left: -100%;
        opacity: 1;
        transition: all 0.5s ease;
    }

    .nav-menu.active {
        background: #242222;
        left: 0;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
    }

    .search-container{
        display: none;
    }

    .nav-links {
        text-align: center;
        padding: 1.2rem;
        width: 100%;
        display: table;
        height: auto;
    }

    .nav-links:hover {
        background-color: #fff;
        color: #242424;
        border-radius: 0;
    }

    .navbar-logo {
        position: absolute;
        top: 0;
        left: 0;
        transform: translate(25%, 50%);
    }

    .menu-icon {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
    }

    .fa-times {
        color: #fff;
        font-size: 2rem;
    }

    .media-display {
        height: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5%;
    }

    .nav-links-mobile {
        display: contents;
        font-size: 1.5rem;
        background-color: transparent;
        color: #fff;
        margin-top: 100px;
        margin-outside:100px;
    }

    .nav-links-mobile:hover {
        color: #646464;
        transition: 250ms;
    }
}

@media screen and (max-width: 768px) {
    .navbar-logo-text{
        display: none;
    }

    .fa-ghost {
        margin-left: 0.8rem;
        margin-top: 0.3rem;
        font-size: 1.8rem;
    }
}