
.article-container{
    width:100%;
    min-height: 100vh;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: linear-gradient(5deg, rgb(7, 7, 10) 0%, rgb(17, 17, 20) 100%);
}

.article-container article{
    color: #fff;
}

.article-ref-div{
    height: 60px;
    width: 100%;
}

.article-container .cover-container{
    width: 70%;
    margin: 5vh 15% 0 15%;
    overflow: hidden;
    position: relative;
    object-fit: scale-down;
}

.article-container .cover-container img{
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.article-container article .title{
    text-align: center;
    font-size: 2.5rem;
    margin: 20px 0 10px 0;
    text-transform: capitalize;
    color: #fff;
}

.article-container article .published-time{
    text-align: center;
    margin-bottom: 20px;
    font-size: 1.2rem;
    color: #384868;
}

.article-container article .published-time i{
    margin-right: 10px;
}

.article-container article .content{
    margin-bottom: 20px;
    width: 70%;
    margin-left: 15%;
}

.article-container article .content :is(img, video){
    object-fit: fill;
    margin: 10px 10px;
    color: white;
}

.article-container article p{
    line-height: 1.8rem;
    margin: 10px;
    color: white;
}

.article-container article h6{
    font-size: 1.2rem;
    line-height: 2rem;
    margin: 10px;
    color: white;
}

.article-container article h5{
    font-size: 1.5rem;
    line-height: 2.2rem;
    margin: 10px;
    color: white;
}

.article-container article h4{
    font-size: 1.8rem;
    line-height: 2.5rem;
    margin: 10px;
    color: white;
}

.article-container article h3{
    font-size: 2rem;
    line-height: 2.8rem;
    margin: 10px;
    color: white;
}

.article-container article h2{
    font-size: 2.2rem;
    line-height: 3rem;
    margin: 10px;
    color: white;
}

.article-container article blockquote{
    margin: 0 50px;
    line-height: 1.5rem;
    color: #ddd;
}

@media screen and (max-width: 768px) {
    .article-container article .content{
        margin-bottom: 20px;
        width: 84%;
        margin-left: 7%;
    }

    .article-container article .content :is(img, video, iframe){
        width: 70%;
        height: auto;
        object-fit: fill;
        margin: 10px 15%;
    }
}