.footer-container {
    background: rgb(16, 16, 20);
    padding: 0 0 2rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

/* Social Icons */
.social-icon-link {
    color: #fff;
    font-size: 24px;
}

.social-media {
    max-width: 1000px;
    width: 100%;
}

.social-media-text {
    color: #fff;
    font-size: 20px;
    font-family: 'PT Sans', sans-serif;
    text-align: center;
    margin: 10px 20px 10px 20px;
}

.social-media-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    max-width: 1000px;
    margin: 40px auto 0 auto;
}

.social-icons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 240px;
    margin: 10px 20px 10px 20px;
}

.social-logo {
    color: #fff;
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
}

.website-rights {
    color: #fff;
    margin-bottom: 16px;
}

@media screen and (max-width: 820px) {
    .footer-links {
        padding-top: 2rem;
    }

    .footer-input {
        width: 100%;
    }

    .btn {
        width: 100%;
    }

    .social-media-wrap {
        flex-direction: column;
    }
}

@media screen and (max-width: 768px) {
}