.nf-container{
    background: url("../../public/images/404.jpeg") center center/cover no-repeat;
    height: 750px;
    width: 100%;
    display: flex;
    flex-direction: column;
    object-fit: contain;
    align-items: center;
    alignment: top;
}

.nf-container h1{
    color: white;
    margin: 200px 0;
}

.nf-container .space-texts{
    display: flex;
    flex-direction: row;
    color: white;
    gap: 25vw
}

@media screen and (max-width: 768px) {
    .nf-container .space-texts{
        display: none;
    }
}