.posts-container{
    width:100%;
    background: #1e1b26;
}

.posts-wrapper{
    width: 95%;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 20px;
}

.posts-box:hover{
    transition: all 0.5s ease-in-out;
    box-shadow: 0 6px 25px rgba(255, 255, 255, 0.15);
}

.posts-box{
    width: 100%;
    padding: 10px;
    border-radius: 10px;
    margin: 15px 0;
    overflow: hidden;
    cursor: pointer;
    background: #050505;
}


.posts-img{
    width: 100%;
    height: 185px;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
}

.posts-img img{
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.posts-img:hover img{
    transition: all 0.5s linear;
    filter: brightness(80%);
}

.posts-img .categories{
    position: absolute;
    left: 0;
    top: 10px;
    display: flex;
    gap: 5px;
}

.posts-img .categories .category{
    padding: 2px 10px;
    font-size: 0.9rem;
    color: white;
    text-decoration: none;
}

.post-category{
    text-decoration: none;
}

.posts-link {
    text-decoration: none;
}

.posts-text h3{
    font-size: 1.1rem;
    text-decoration: none;
    color: white;
    font-weight: 500;
    margin: 5px 0;
    line-height: 25px;
}

.posts-text h3:hover{
    color: #7701a7;
}

.posts-text .category-time{
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 0.9rem;
    margin-top: 5px;
    font-weight: 500;
}

.posts-text .category-time .published-time{
    color: #888;
}

.posts-text .excerpt{
    font-size: 0.9rem;
    color: #888;
}